import Vue from 'vue'
import App from './App.vue'
import router from './router'
import FirebaseVue from './firebase'
import VueMask from 'v-mask'
import axios from 'axios'
import moment from 'moment'
import pagarme from 'pagarme'
import VueApexCharts from 'vue-apexcharts'
import firebase from 'firebase/app'
import 'firebase/auth'
import './assets/scss/app.scss'
import 'popper.js'
import 'bootstrap'
import VueSmoothScroll from 'v-smooth-scroll'
import VueScrollTo from 'vue-scrollto'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VCalendar from 'v-calendar'
import CookieLaw from 'vue-cookie-law'
import VueGtag from 'vue-gtag'
import VueCryptojs from 'vue-cryptojs'
import VueCookie from 'vue-cookie'
import VueMoney from 'v-money'

let app
Vue.use(VueCryptojs)
Vue.use(CookieLaw)
Vue.use(VueYouTubeEmbed)
Vue.use(VueScrollTo)
Vue.use(FirebaseVue)
Vue.use(VueMask)
Vue.use(VueMoney)
Vue.use(VueApexCharts)
Vue.use(VueSmoothScroll)
Vue.use(VueScrollTo)
Vue.use(VCalendar)
Vue.use(VueCookie)
Vue.component('apexchart', VueApexCharts)
Vue.prototype.$http = axios
Vue.prototype.$pagarme = pagarme
Vue.prototype.moment = moment
Vue.config.productionTip = false
Vue.prototype.$userPlan = ''
Vue.prototype.$profile = ''
Vue.prototype.$screen = {
  height: window.screen.height,
  width: window.screen.width,
  cardHeight: '0px'
}
Vue.prototype.$user = ''
Vue.prototype.$certificateCache = null
Vue.use(VueGtag, {
  config: { id: 'AW-722129369' }
})

firebase.auth().onAuthStateChanged(user => {
  document.title = 'Emissão Simples'
  window.uid = user ? user.uid : null
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})
