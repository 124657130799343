import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'

Vue.use(Router)

const routes = [
  {
    path: '/home/',
    name: 'home',
    meta: {
      title: 'SimplesConnect',
      public: true
    },
    component: () => import(/* webpackChunkName: "Home" */ '../pages/home/Home')
  },
  {
    path: '/:profile/:document&:password/',
    name: 'index',
    meta: {
      title: 'Entrar',
      public: true
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/login/Login')
  },
  {
    path: '/:profile?/',
    name: 'index',
    meta: {
      title: 'Entrar',
      public: true
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/login/Login')
  }
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'Área do Cliente | ' + to.meta.title
  document.public = to.meta.public

  if (to.matched.some(record => !record.meta.public)) {
    if (!firebase.auth().currentUser) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }

  // if (!window.uid && !to.meta.public) {
  //   next({ name: 'login' })
  // } else {
  //   next()
  // }
})

export default router
