<template>
  <div id="app">
    <base-spinner/>
    <router-view/>
  </div>
</template>

<script>
import BaseSpinner from './components/global/BaseSpinner'

export default {
  name: 'App',
  components: {
    BaseSpinner
  }
}
</script>

<style lang="scss">
#app{
  min-height: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  background-color: var(--background-gray);
}
</style>
